import { SparklesIcon } from '@heroicons/react/24/solid';
import { Tooltip } from '@mui/material';
import useThemeStore from 'Theme/store';
import { cn } from 'utils/styles';

const ModelTemplateCardDisplay = ({
  multi_default_models,
  default_model,
  id,
}) => {
  const getContent = () => {
    if (multi_default_models?.length > 1) {
      return (
        <Tooltip
          title={
            <span
              dangerouslySetInnerHTML={{
                __html: multi_default_models
                  .map((model) => model.name)
                  .join('<br>'),
              }}
            />
          }
          arrow
        >
          <p className="flex gap-1 items-center">
            {multi_default_models[0]?.name} + {multi_default_models.length - 1}
            <SparklesIcon className="w-[10px] h-[10px] ml-1" />
          </p>
        </Tooltip>
      );
    } else if (multi_default_models?.length === 1) {
      return multi_default_models[0]?.name;
    } else if (default_model) {
      return default_model?.name;
    } else if (imageGenerationTools.includes(id)) {
      return setImageToolNames(id);
    } else {
      return '-- / --';
    }
  };

  const imageGenerationTools = [
    '65805947d92e370a3d5625c6',
    '66c786d7e26442bc593e575d',
  ];

  const setImageToolNames = (tool) => {
    if (tool?.id == '65805947d92e370a3d5625c6') {
      return 'OpenAi: Dall-E 3';
    } else if (tool?.id == '66c786d7e26442bc593e575d') {
      return 'Fal-Ai: FLUX.1 [pro]';
    }
    return '';
  };

  const { theme } = useThemeStore();
  return (
    <div className="flex items-center">
      <p
        className={cn(
          'uppercase px-[6px] rounded text-[10px] font-[700] leading-[14px] w-fit ',
          {
            'text-cool-gray bg-[#E1E1E5]': theme === 'light',
            'text-white bg-sonic-silver': theme === 'dark',
          }
        )}
      >
        {getContent()}
      </p>
    </div>
  );
};

export default ModelTemplateCardDisplay;
