const dev = {
  baseURL: 'http://localhost:3080/api/',
  socketURL: 'https://sio.straico.com',
  landingPageUrl: 'http://localhost:3080',
  stripe: {
    free: 'free_trial',
    entry: 'price_1Mzst5ImOS91TsUeaRCOjzb9',
    pro: 'price_1JLQhlHIZYvvnKlakrqF8khB',
  },
  baseFrontUrl: 'http://localhost:3000',
  enviroment: 'development',
};

const prod = {
  baseURL: '/api/',
  socketURL: 'https://sio.straico.com',
  landingPageUrl: 'https://app.openaitemplate.com',
  stripe: {
    free: 'free_trial',
    entry: 'price_1JLpWpHIZYvvnKlaHxiCr7Js',
    pro: 'price_1JLpWxHIZYvvnKlamATZ7Awm',
  },
  baseFrontUrl: 'https://staging.straico.com',
  enviroment: 'production',
};

const config = process.env.NODE_ENV === 'development' ? dev : prod;

export default config;
