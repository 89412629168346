import { XMarkIcon } from '@heroicons/react/24/solid';
import { Dialog, IconButton } from '@mui/material';
import useThemeStore from 'Theme/store';
import { cn } from 'utils/styles';

const CommonModal = ({
  open,
  handleClose,
  children,
  withoutPadding = false,
  withCloseButton = false,
  withoutContainer = false,
  ...props
}) => {
  const { theme } = useThemeStore();
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      maxWidth="sm"
      {...props}
      PaperProps={{
        sx: {
          position: 'relative',
          backgroundColor: theme == 'dark' ? '#252425' : '#F7F7F8',
          backgroundImage: 'none',
          borderRadius: '21px',
          minWidth: '400px',
          minHeight: '300px',
          ...props.sx,
        },
      }}
    >
      {/* Close button */}
      {!withCloseButton && (
        <div className={cn('absolute top-[6px] right-[6px] group')}>
          <IconButton onClick={handleClose}>
            <XMarkIcon
              className={cn('h-3 w-3 cursor-pointer', {
                'text-cool-gray group-hover:text-battleship-gray':
                  theme !== 'dark',
                'text-seasalt group-hover:text-quicksilver': theme === 'dark',
              })}
            />
          </IconButton>
        </div>
      )}
      {withoutContainer ? (
        <>{children}</>
      ) : (
        <div
          className={cn('flex justify-center flex-col h-full w-full', {
            'px-6 py-4': !withoutPadding,
          })}
        >
          {children}
        </div>
      )}
    </Dialog>
  );
};

export default CommonModal;
