import {
  BoltIcon,
  PaperClipIcon,
  RocketLaunchIcon,
  TagIcon,
  UserIcon,
} from '@heroicons/react/24/solid';
import AvatarSvgIcon from '@straico-ui/commons/AvatarSvgIcon';
import CustomToolTip from '@straico-ui/commons/CustomToolTip';
import ModelTemplateCardDisplay from '@straico-ui/commons/ModelTemplateCardDisplay';
import useThemeStore from 'Theme/store';
import Emoji from 'react-emojis';
import { cn } from 'utils/styles';

const TemplateCard = ({
  id,
  category,
  title,
  description,
  userInfo,
  files,
  runs,
  multi_default_models,
  default_model,
  onClick,
  icon,
}) => {
  const { theme } = useThemeStore();
  // Formatter
  const formatter = new Intl.NumberFormat('en-US');

  return (
    <div
      onClick={onClick}
      className={cn(
        'flex flex-col gap-2 w-[300px] h-[170px] rounded-xl p-4 shadow-md transition-shadow justify-between px-3 py-[10px] cursor-pointer font-figtree',
        {
          'bg-white  text-[#211E21]': theme !== 'dark',
          'bg-night-black  text-white': theme === 'dark',
        }
      )}
    >
      <div className="flex items-center justify-between space-x-2 text-graphite-100 dark:text-[#EFEFEF]">
        <div className="flex items-center gap-1 dark:text-palladium">
          <TagIcon className={'size-4 '} />
          <h2 className="text-[12px] font-bold">{category}</h2>
        </div>
        <div className="flex items-center gap-1">
          <div className="flex space-x-1 items-center">
            {userInfo !== null ? (
              userInfo?.avatar?.svg ? (
                <CustomToolTip
                  title={`${userInfo.fname ?? ''} ${userInfo.lname ?? ''}`}
                >
                  <AvatarSvgIcon svg={userInfo.avatar.svg} className="size-7" />
                </CustomToolTip>
              ) : (
                <UserIcon className="w-4 transition rounded-full bg-gray-300" />
              )
            ) : (
              <BoltIcon className="w-4 transition rounded-full text-white bg-nue-blue" />
            )}
          </div>
          <div className={cn('flex space-x-1 items-center')}>
            <RocketLaunchIcon className="w-4" />
            <span className="text-xs font-[500]">
              {runs ? formatter.format(runs) : 0}
            </span>
          </div>
        </div>
      </div>
      <div className="flex items-center space-x-2">
        <div className="rounded-full text-2xl">
          <Emoji emoji={icon ?? 'high-voltage'} color="red" />
        </div>
        <h2 className="font-[600] leading-5 text-left line-clamp-2">{title}</h2>
      </div>
      <div className="flex flex-col">
        <p className="line-clamp-2 font-normal text-xs ">{description}</p>
      </div>
      <div className="flex mt-2 space-x-2 align-middle items-center justify-between">
        <div className="max-w-[200px]">
          <ModelTemplateCardDisplay
            multi_default_models={multi_default_models}
            default_model={default_model}
            id={id}
          />
        </div>
        <div className="flex items-center gap-1 justify-center">
          <div>
            {files.length > 0 && (
              <div className="flex space-x-1 items-center">
                <PaperClipIcon className={'w-5'} />
                <span className="text-xs font-[500] uppercase">
                  {files.length}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const LoadingTemplateCard = () => {
  const { theme } = useThemeStore();
  return (
    <div
      className={cn(
        'flex flex-col gap-2 w-[300px] h-[170px] rounded-xl p-4 shadow-md transition-shadow justify-between px-3 py-[10px] cursor-pointer font-figtree',
        {
          'bg-white  text-[#211E21]': theme !== 'dark',
          'bg-night-black  text-white': theme === 'dark',
        }
      )}
    >
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center space-x-2">
          <div className="h-4 bg-gray-200 rounded w-24 animate-pulse"></div>
        </div>
        <div className="flex items-center space-x-1">
          <div className="w-4 h-4 bg-gray-200 rounded-full animate-pulse"></div>
          <div className="w-4 h-4 bg-gray-200 rounded-full animate-pulse"></div>
          <div className="h-4 bg-gray-200 rounded w-16 animate-pulse"></div>
        </div>
      </div>
      <div className="space-y-2">
        <div className="h-6 bg-gray-200 rounded w-3/4 animate-pulse"></div>
        <div className="h-4 bg-gray-200 rounded w-full animate-pulse"></div>
        <div className="h-4 bg-gray-200 rounded w-2/3 animate-pulse"></div>
      </div>
      <div className="mt-4 flex items-center justify-between">
        <div className="h-4 bg-gray-200 rounded w-24 animate-pulse"></div>
        <div className="h-4 bg-gray-200 rounded w-16 animate-pulse"></div>
      </div>
    </div>
  );
};

export default TemplateCard;
