/* eslint-disable no-console */
/* eslint-disable react/display-name */
import { memo } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';

export const MemoizedOriginalPrompt = memo(
  ({ originalPrompt, isExpanded, toggleExpand, theme }) => {
    if (!originalPrompt) return null;
    return (
      <div
        className={`font-medium p-2 rounded-md mb-2 mx-auto w-full text-left overflow-hidden relative ${
          isExpanded ? 'whitespace-normal' : 'whitespace-nowrap'
        } overflow-ellipsis ${
          theme === 'dark'
            ? 'bg-gray-400 bg-opacity-80 text-gray-100 text-opacity-70'
            : 'bg-gray-200 bg-opacity-80 text-gray-600 text-opacity-70'
        }`}
      >
        <span>Original Prompt: {originalPrompt}</span>

        <ChevronDownIcon
          onClick={toggleExpand}
          className={`w-5 h-5 absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer transition-transform ${
            isExpanded ? 'rotate-180' : ''
          }`}
        />
      </div>
    );
  }
);
