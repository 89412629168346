// MUI
import { Button } from '@mui/material';
// Icons
import { TrashIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
// Components
import CommonModal from '../CommonModal';

const ModalConfirmClose = ({
  open,
  onClose,
  onCancel,
  title = 'Work Not Saved!',
  description = 'Exiting now will discard any changes made to your template. Are you sure you want to proceed?',
  confirmButtonText = 'Discard & exit',
  cancelButtonText = 'Continue editing',
}) => {
  //Method's
  const handleCancel = () => {
    onCancel();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <CommonModal
      open={open}
      handleClose={() => {}}
      sx={{
        minHeight: 'initial',
      }}
      withoutPadding
    >
      <div className="h-full flex flex-col justify-center items-center gap-5 font-figtree text-raisin-black dark:text-crystal-bell px-[30px] py-7">
        <div className="text-2xl leading-7 font-bold">{title}</div>
        <div className="w-96 text-base font-normal leading-6 text-black text-center">
          {description}
        </div>
        <div className="flex w-full gap-2 items-center justify-center pt-2">
          <Button
            variant="contained"
            color="primary"
            onClick={handleClose}
            sx={{
              fontWeight: 600,
              fontSize: '18px',
              lineHeight: '24px',
              fontFamily: 'Figtree',
              borderRadius: '12px',
              textTransform: 'none',
              padding: '8px 16px',
            }}
            startIcon={<TrashIcon className="h-5 w-5" />}
          >
            {confirmButtonText}
          </Button>
          <Button
            color="primary"
            onClick={handleCancel}
            sx={{
              fontWeight: 600,
              fontSize: '18px',
              lineHeight: '24px',
              fontFamily: 'Figtree',
              borderRadius: '12px',
              textTransform: 'none',
              padding: '8px 16px',
              backgroundColor: '#D4D5E6',
              '&:hover': {
                backgroundColor: '#D4D5E6B2',
              },
            }}
            startIcon={<PencilSquareIcon className="h-5 w-5" />}
          >
            {cancelButtonText}
          </Button>
        </div>
      </div>
    </CommonModal>
  );
};

export default ModalConfirmClose;
