/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import {
  ArrowDownTrayIcon,
  MusicalNoteIcon,
} from '@heroicons/react/24/outline';
import Button from './Button';

import {
  SparklesIcon,
  StarIcon,
  SpeakerWaveIcon,
} from '@heroicons/react/24/outline';
import { CurrencyDollarIcon, XMarkIcon } from '@heroicons/react/24/solid';

import toast from 'react-hot-toast';
import { toastReward } from 'utils/toastCall';
import ReactAudioPlayer from 'react-audio-player';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import useThemeStore from 'Theme/store';
import { IconButton } from '@mui/material';

const TextSpeechDialog = inject('store')(
  observer(({ store, onClose, script }) => {
    const concatenateTexts = (data) => {
      let concatenatedText = '';
      for (const item of data) {
        if (item.type === 'text') {
          concatenatedText += item.text + ' ';
        }
      }
      return concatenatedText.trim();
    };

    const [text, setText] = useState(
      typeof script === 'string' ? script : concatenateTexts(script)
    );
    const [voice, setVoice] = useState('');
    const [quality, setQuality] = useState('');
    const [audioUrl, setAudioUrl] = useState('');
    const [showAudio, setShowAudio] = useState(false);
    const [showLoading, setShowLoading] = useState(false);

    const handleChangeVoice = (event) => {
      setVoice(event.target.value);
      setShowAudio(false);
    };

    const handleChangeQuality = (event) => {
      setQuality(event.target.value);
      setShowAudio(false);
    };

    const handleChangeText = (event) => {
      setText(event.target.value);
      setShowAudio(false);
    };

    const navigate = useNavigate();

    useEffect(() => {
      setVoice('alloy');
      setQuality('tts-1');
    }, []);

    const [chunkProgress, setChunkProgress] = useState(0);
    const [isConcatenating, setIsConcatenating] = useState(false);

    const WORD_LIMIT = 670;

    const splitTextIntoChunks = (text, maxLength) => {
      const chunks = [];
      while (text.length > 0) {
        if (text.length <= maxLength) {
          chunks.push(text);
          break;
        }
        let chunk = text.slice(0, maxLength);
        const lastPeriodIndex = chunk.lastIndexOf('.');
        const lastSpaceIndex = chunk.lastIndexOf(' ');

        if (lastPeriodIndex > 0 && lastPeriodIndex < maxLength - 1) {
          chunk = text.slice(0, lastPeriodIndex + 1);
        } else if (lastSpaceIndex > 0 && lastSpaceIndex < maxLength) {
          chunk = text.slice(0, lastSpaceIndex);
        }

        chunks.push(chunk);
        text = text.slice(chunk.length).trim();
      }
      return chunks;
    };

    const fetchAudioForChunk = async (chunk, voice, quality) => {
      try {
        const response = await store.api.post(`/file/tts`, {
          text: chunk,
          voice: voice,
          quality: quality,
          coins: calculateCoins(chunk.split(' ').length),
        });

        if (response.data.success) {
          return response.data.url;
        } else {
          if (response.data.type == 'coins') {
            toastReward({
              title: 'Insufficient Coins 😭',
              message: 'Recharge your account for more coins',
              linkText: "It's quick and easy. Click here!",
              linkUrl: '/my-profile/pricing',
              image: '/growing.gif',
              time: 10000,
            });
          } else if (response.data.type == 'words') {
            toast('Maximum 670 words per audio generated', {
              icon: '⚠️',
            });
          }
          throw new Error(
            response.data.message || 'Error converting text to audio'
          );
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    };

    const concatenateAudios = async (audioUrls) => {
      try {
        const response = await store.api.post(`file/concatenate-audio`, {
          audioUrls: audioUrls,
        });

        if (response.data.success) {
          return response.data.url;
        } else {
          throw new Error(
            response.data.message || 'Error concatenating audios'
          );
        }
      } catch (error) {
        console.error('Error concatenating audios:', error);
        throw error;
      }
    };

    const generateTextToAudio = async () => {
      setShowAudio(false);
      setShowLoading(true);
      setChunkProgress(0);
      setIsConcatenating(false);

      try {
        toast(
          'Please hold on, converting your text to speech may take a moment.',
          {
            icon: '⌛️',
          }
        );

        const chunks = splitTextIntoChunks(text, WORD_LIMIT);
        const totalChunks = chunks.length;
        const audioUrls = [];

        for (let i = 0; i < chunks.length; i++) {
          const chunk = chunks[i];
          const url = await fetchAudioForChunk(chunk, voice, quality);
          audioUrls.push(url);

          setChunkProgress(((i + 1) / totalChunks) * 100);
        }

        setIsConcatenating(true);

        const finalAudioUrl = await concatenateAudios(audioUrls);

        setShowAudio(true);
        setAudioUrl(finalAudioUrl);
        setShowLoading(false);
        setIsConcatenating(false);
      } catch (error) {
        console.error(error);
        setShowLoading(false);
        setShowAudio(false);
      }
    };

    const getInputWords = (text) => {
      let count = text.trim().split(/\s+/).filter(Boolean).length;
      return count;
    };

    const calculateCoins = (words) => {
      let base;
      if (quality == 'tts-1') {
        base = 4 / 100; // 4 coins for 100 words
      } else {
        base = 8 / 100;
      }
      return (words * base).toFixed(2);
    };

    const downloadAudio = (audioUrl) => {
      const anchor = document.createElement('a');
      anchor.href = audioUrl;
      const fileName = audioUrl.split('/').pop();
      anchor.download = fileName;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    };

    const { theme: themeValue } = useThemeStore();

    return (
      <>
        <div className="fixed inset-x-0 px-4 py-6 inset-0 flex items-center justify-center z-50 font-figtree">
          <div className="fixed inset-0 transition-opacity" onClick={onClose}>
            <div className="absolute inset-0 bg-raisin-black opacity-55"></div>
          </div>

          <div
            className={`relative ${
              themeValue == 'dark' ? 'bg-lead' : 'bg-seasalt'
            } rounded-[21px] shadow-xl transform transition-all sm:max-w-lg sm:w-full max-h-full`}
          >
            <div
              className={`py-7 px-4 h-min ${
                store.profile.plan == 'freeTos' && 'opacity-20 blur-[1px]'
              }`}
            >
              <div
                className={`px-3 h-full max-h-[520px] overflow-auto overflow-rtl ${
                  themeValue == 'dark'
                    ? 'overflow-rtl-dark'
                    : 'overflow-rtl-light'
                }`}
              >
                {/* Title bar */}
                <div className="text-raisin-black dark:text-crystal-bell text-2xl leading-7 font-bold">
                  Text to Speech
                </div>
                <div className="my-5 font-light italic text-black dark:text-white">
                  Quickly proofread, pick a voice, and generate speech. It's
                  that simple. Play or download your audio in seconds!
                </div>

                {/* Close button */}
                <div className="absolute right-[6px] top-[6px] group">
                  <IconButton onClick={onClose}>
                    <XMarkIcon
                      className={`w-3 h-3 ${
                        themeValue == 'dark'
                          ? 'text-quicksilver'
                          : 'text-cool-gray'
                      }`}
                    />
                  </IconButton>
                </div>

                {/* Form data */}
                <div className="flex flex-col justify-between">
                  <div className="mb-3 text-raisin-black dark:text-crystal-bell font-semibold">
                    Text
                  </div>
                  <textarea
                    value={text}
                    onChange={handleChangeText}
                    name="description"
                    className={`w-full px-[10px] py-[8px] rounded-[10px] ${
                      themeValue == 'dark'
                        ? 'bg-night-black text-crystal-bell'
                        : 'bg-white text-raisin-black'
                    } border border-platinum border-solid`}
                    style={{ resize: 'none' }}
                    placeholder="A description for your prompt"
                    rows={4}
                  />

                  <div className="mt-5 mb-3 text-raisin-black dark:text-crystal-bell font-semibold">
                    Voice
                  </div>
                  <Select
                    value={voice}
                    onChange={handleChangeVoice}
                    sx={{
                      fontFamily: 'Figtree',
                      borderRadius: '10px',
                      background: themeValue == 'dark' ? '#39383A' : '#FFFFFF',
                      '& .MuiSelect-select': { padding: '8px 10px' },
                    }}
                  >
                    <MenuItem sx={{ fontFamily: 'Figtree' }} value={'alloy'}>
                      Alloy - Young adult - Male
                    </MenuItem>
                    <MenuItem sx={{ fontFamily: 'Figtree' }} value={'echo'}>
                      Echo - Young adult - Male
                    </MenuItem>
                    <MenuItem sx={{ fontFamily: 'Figtree' }} value={'fable'}>
                      Fable - Young adult - Male
                    </MenuItem>
                    <MenuItem sx={{ fontFamily: 'Figtree' }} value={'nova'}>
                      Nova - Young adult - Female
                    </MenuItem>
                    <MenuItem sx={{ fontFamily: 'Figtree' }} value={'onyx'}>
                      Onyx - Senior - Male
                    </MenuItem>
                    <MenuItem sx={{ fontFamily: 'Figtree' }} value={'shimmer'}>
                      Shimmer - Young adult - Female
                    </MenuItem>
                  </Select>

                  <div className="mt-5 mb-3 text-raisin-black dark:text-crystal-bell font-semibold">
                    Quality
                  </div>
                  <Select
                    value={quality}
                    onChange={handleChangeQuality}
                    sx={{
                      fontFamily: 'Figtree',
                      borderRadius: '10px',
                      background: themeValue == 'dark' ? '#39383A' : '#FFFFFF',
                      '& .MuiSelect-select': { padding: '8px 10px' },
                    }}
                  >
                    <MenuItem sx={{ fontFamily: 'Figtree' }} value={'tts-1'}>
                      Text to Speech - Standard{' '}
                    </MenuItem>
                    <MenuItem sx={{ fontFamily: 'Figtree' }} value={'tts-1-hd'}>
                      Text to Speech - High Definition{' '}
                    </MenuItem>
                  </Select>
                </div>

                {/* Audio block */}
                {showAudio ? (
                  <>
                    <div className="mt-5 mb-3 text-raisin-black dark:text-crystal-bell font-semibold">
                      Audio
                    </div>
                    <div className="flex justify-center">
                      <ReactAudioPlayer src={audioUrl} controls />
                    </div>
                    <div
                      className={`flex justify-center w-full px-6 pb-6 bottom-0 mt-4`}
                    >
                      <Button
                        Icon={ArrowDownTrayIcon}
                        title={'Download'}
                        noMarginTop
                        onClick={() => downloadAudio(audioUrl)}
                        iconSize="w-[18px] h-[18px]"
                        customRound="rounded-xl"
                        customPaddig="px-3 py-2"
                        customWeight="font-semibold"
                        noUppercase
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {/* Loading Bar */}
                {showLoading ? (
                  <>
                    <div className="flex justify-center">
                      <div
                        className={`mt-4 mb-4 ${
                          themeValue == 'dark'
                            ? 'text-quicksilver'
                            : 'text-cool-gray'
                        } text-sm font-normal`}
                      >
                        Generating voice... please wait{' '}
                      </div>
                    </div>
                    <div className="flex justify-center">
                      <img src="/straico_loader.gif" className="h-10"></img>
                    </div>
                    <div className="flex justify-center">
                      <progress
                        value={50}
                        max="100"
                        style={{
                          width: '100%',
                          marginTop: '0.5rem',
                          WebkitAppearance: 'none',
                          appearance: 'none',
                          height: '0.75rem',
                          borderRadius: '0.375rem',
                          overflow: 'hidden',
                        }}
                        className="progress-accent"
                      ></progress>
                    </div>
                    {isConcatenating && (
                      <div className="flex justify-center">
                        <div
                          className={`mt-4 mb-4 ${
                            themeValue == 'dark'
                              ? 'text-quicksilver'
                              : 'text-cool-gray'
                          } text-sm font-normal`}
                        >
                          Concatenating audio chunks... please wait{' '}
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <></>
                )}

                <div className="flex justify-between items-end">
                  {/* Coins calculator */}
                  <div className="flex gap-1 text-xs items-center justify-end">
                    <div
                      className={`flex flex-row items-center px-[2px] ${
                        themeValue == 'dark'
                          ? 'bg-sonic-silver text-lead'
                          : 'bg-cool-gray text-white'
                      } rounded-[20px] font-semibold py-[2px]`}
                    >
                      <CurrencyDollarIcon className="w-4 h-4" />
                      <span className="mx-1">
                        {calculateCoins(getInputWords(text))}
                      </span>
                    </div>
                    <div
                      className={`${
                        themeValue == 'dark'
                          ? 'text-sonic-silver '
                          : 'text-cool-gray '
                      } font-medium`}
                    >
                      ·{' '}
                      <span>{getInputWords(text).toLocaleString('en-US')}</span>{' '}
                      words
                    </div>
                  </div>

                  {/* Generator button */}
                  <div
                    className={` ${
                      store.profile.plan == 'freeTos' &&
                      'opacity-30 blur-[1px] px'
                    }`}
                  >
                    <Button
                      Icon={SpeakerWaveIcon}
                      title={'Generate'}
                      disabled={!text || showLoading}
                      onClick={() => generateTextToAudio()}
                      iconSize="w-[18px] h-[18px]"
                      customRound="rounded-xl"
                      customPaddig="px-3 py-2"
                      customWeight="font-semibold"
                      noUppercase
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Free plan disclaimer */}
            {store.profile.plan == 'freeTos' && (
              <div className=" absolute  justify-center top-0 h-full m-auto flex flex-col items-center  px-20 ">
                <SparklesIcon
                  className={`h-6 flex justify-center w-full mb-5 ${
                    themeValue == 'dark'
                      ? 'text-stargate-shimmer'
                      : 'text-violet-blue'
                  }`}
                />
                <div
                  className={`text-center not-italic ${
                    themeValue == 'dark'
                      ? 'text-stargate-shimmer'
                      : 'text-violet-blue'
                  } text-xl font-bold uppercase pb-3`}
                >
                  {store.profile.trial_end
                    ? 'Looking for premium benefits?'
                    : 'Ready for a power-up?'}
                </div>
                {store.profile.trial_end ? (
                  <div
                    className={`text-center not-italic text-base font-medium pb-5 ${
                      themeValue == 'dark'
                        ? 'text-crystal-bell'
                        : 'text-raisin-black'
                    }`}
                  >
                    <a
                      href="/my-profile/pricing"
                      className="text-violet-blue text-base font-semibold underline"
                    >
                      Upgrade
                    </a>{' '}
                    to a paid subscription to text to speech and keep unlocking
                    your AI superpowers.
                  </div>
                ) : (
                  <div
                    className={`text-center not-italic text-base font-medium pb-5 ${
                      themeValue == 'dark'
                        ? 'text-crystal-bell'
                        : 'text-raisin-black'
                    }`}
                  >
                    Gain immediate access to text to speech and others with our{' '}
                    <a
                      href="/my-profile/pricing"
                      className="text-violet-blue text-base font-semibold underline"
                    >
                      {process.env.REACT_APP_TRIAL_PERIOD_DAYS}-day trial.
                    </a>{' '}
                    for just $1.
                  </div>
                )}
                <div className="flex justify-center w-full">
                  <button
                    type="button"
                    className="bg-nue-blue shadow-md rounded-xl py-1 px-3 text-white font-medium text-center text-base flex"
                    onClick={() => {
                      navigate('/my-profile/pricing');
                    }}
                  >
                    <StarIcon className="w-5 h-5  mr-2" />{' '}
                    {store.profile.trial_end
                      ? 'Upgrade now'
                      : `try for ${process.env.REACT_APP_TRIAL_PERIOD_DAYS} days for $1`}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  })
);

export default TextSpeechDialog;
