import React, { useCallback, useEffect, useState } from 'react';
// React DOM
import { useLocation } from 'react-router-dom/dist';
// Mobx
import { inject, observer } from 'mobx-react';
// Store
import useTemplateModalStore from './store';
// Icons
import {
  ArrowDownTrayIcon,
  BoltIcon,
  Cog6ToothIcon,
  EyeIcon,
  LinkIcon,
  PaperAirplaneIcon,
  PaperClipIcon,
  StarIcon,
  VariableIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import Tooltip from '@mui/material/Tooltip';

import { StarIcon as StarOutlineIcon } from '@heroicons/react/24/outline';
// Utils
import { toast } from 'react-hot-toast';
import { cn } from 'utils/styles';
import { validateObjectHasEmptyFields } from './utils/functions';
// MUI
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
// Context
import { useChat } from 'Context/Chat/Chat.context';
// RHF
import { FormProvider, useForm, useWatch } from 'react-hook-form';
// Config
import config from 'config';
// Theme
import useThemeStore from 'Theme/store';
// Emoji
import Emoji from 'react-emojis';
// Components
import { ArrowLeftCircleIcon } from '@heroicons/react/24/solid';
import ShareInput from 'Components/Common/ShareInput/ShareInput';
import Tab from 'Components/Common/Tab';
import CommonModal from 'Features/CreateTemplatesForm/Components/CommonModal';
import ModalConfirmClose from 'Features/CreateTemplatesForm/Components/ModalConfirmClose';
import useClipboardComponent from 'Hooks/useClipboardComponent';
import usePromptLibraryStore from '../PromptLibrary/store';
import useShareLinkTool from '../PromptModal/store';
import AdvancedSection from './components/AdvancedSection';
import AttachmentsSection from './components/AttachmentsSection';
import ErrorModal from './components/ErrorModal';
import VariableSection from './components/VariableSection';
import ViewPromptSection from './components/ViewPromptSection';

const TemplateModal = ({
  store,
  handleTemplateModel,
  onClose,
  handleSubmitModal,
}) => {
  // Context
  const {
    selectedTool, // Template base info
    selectedPrompt, // Template variables and prompt
    promptValue, // Template prompt value
    promptListByTool,
  } = useChat();
  // Store
  const {
    openModal,
    setOpenModal,
    tab: currentTab,
    setTab,
    setErrorModal,
    setErrorModalReason,
    setModels,
    confirmCloseModal,
    setConfirmCloseModal,
  } = useTemplateModalStore();
  //Get models
  const getModels = useCallback(async () => {
    try {
      const response = await store.api.get(`/model`);
      const data = await response.data;
      let transformedModels = data.models;
      setModels(transformedModels);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getModels();
  }, []);
  // Modal
  const handleCloseClickOutside = () => {
    setConfirmCloseModal(true);
  };
  const handleClose = () => {
    onClose(defaultParams);
    setOpenModal(false);
    reset();
    setShowShare(false);
    setSendDirect(false);
  };

  const handleConfirmClose = () => {
    setConfirmCloseModal(false);
    onClose(defaultParams);
    setOpenModal(false);
    reset();
    setShowShare(false);
    setSendDirect(false);
  };

  const handleCancelConfirmClose = () => {
    setConfirmCloseModal(false);
  };

  // Tab
  const [blockTabs, setBlockTabs] = React.useState([]);
  const handleChangeTab = (value) => {
    setTab(value);
    setShowShare(false);
  };

  useEffect(() => {
    if (selectedPrompt) {
      // Block attachments tab if no attachments
      if (
        selectedPrompt?.prompts?.filter((i) =>
          ['image', 'attachment'].includes(i.type)
        ).length === 0 &&
        selectedTool?.files?.length === 0
      ) {
        setBlockTabs((prev) => [...prev, 'Attachments']);
      }
    } else {
      setBlockTabs((prev) => prev.filter((i) => i !== 'Attachments'));
    }
  }, [selectedPrompt]);

  const TABS = [
    {
      icon: <VariableIcon className="w-6 h-6" />,
      text: 'Variables',
      value: 1,
      render: () => (
        <VariableSection
          selectedPrompt={selectedPrompt}
          runningSample={runningSample}
        />
      ),
    },
    {
      icon: <PaperClipIcon className="w-6 h-6" />,
      text: 'Attachments',
      value: 2,
      render: () => <AttachmentsSection />,
    },
    {
      icon: <Cog6ToothIcon className="w-6 h-6" />,
      text: 'Advanced',
      value: 3,
      render: () => <AdvancedSection closeTemplateModal={handleClose} />,
    },
    {
      icon: <EyeIcon className="w-6 h-6" />,
      text: 'View prompt',
      value: 4,
      render: () => (
        <ViewPromptSection
          selectedTool={selectedTool}
          promptValue={promptValue}
          formValues={watch()}
        />
      ),
    },
  ];
  // Tabs filter
  const tabs = React.useMemo(() => {
    return TABS.filter((i) => !blockTabs.includes(i.text));
  }, [blockTabs]);

  // Global form state
  const methods = useForm({
    mode: 'all',
  });

  const { handleSubmit, reset, watch, setValue } = methods;

  useEffect(() => {
    reset(); // Reset form when selectedPrompt changes
    setTab(1); // Reset tab when selectedPrompt changes
  }, [openModal]);

  const location = useLocation();
  const [defaultParams, setDefaultParams] = useState({});

  // Reset form default values from URL params
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    // object params
    const paramsObj = Object.fromEntries(params);
    delete paramsObj['selectedTool'];
    delete paramsObj['chat'];
    delete paramsObj['fpr'];

    setDefaultParams(paramsObj);
    reset(paramsObj);
  }, [location, tabs]);

  const getSelectedRawPrompt = (list, prompt) => {
    return list.filter((item) => item.name == prompt.title)[0];
  };

  const handleInsert = (data) => {
    const attachments = selectedPrompt?.prompts?.filter((i) =>
      ['image', 'attachment'].includes(i.type)
    );

    // Delete for data the keys that exist in the attachments
    attachments?.forEach((attachment) => {
      delete data[attachment.attr];
    });

    // conditions to pass the data
    const emptyFields = validateObjectHasEmptyFields(data);
    const emptyAttachments =
      attachments?.length > 0 &&
      Object.values(watch('files') ?? {}).length === 0;

    if (emptyFields || emptyAttachments) {
      setErrorModal(true);
      setErrorModalReason({
        emptyFields,
        emptyAttachments,
      });
    } else {
      handleSendData();
    }
  };

  const handleSendData = (runSampleAuto) => {
    //CHECK IF THE USERS CAN USE PREMIUM MODELS
    if (store.profile.plan == 'freeTos' && !selectedTool.useTool) {
      toast.success(
        "Looks like that template is using a premium model, but we're switching gears to GPT-3.5 Turbo! 🚀 Or, you could subscribe to a plan and unlock new powers in Straico! ⚡️",
        {
          duration: 8000,
        }
      );
    } else {
      if (selectedTool?.multi_default_models.length > 0) {
        handleTemplateModel(selectedTool.multi_default_models);
      } else if (
        selectedTool?.default_model &&
        Object.keys(selectedTool?.default_model).length > 0
      ) {
        handleTemplateModel([selectedTool?.default_model]);
      }
    }

    let files = Object.entries(watch('files') ?? {}).map(([, value]) =>
      convertFile(value)
    );
    files = [...files, ...(selectedTool?.files ?? [])];
    let rawPrompt = getSelectedRawPrompt(promptListByTool, selectedPrompt);
    handleSubmitModal(Object.entries(watch()), rawPrompt, files, runSampleAuto);

    handleClose();
  };

  const convertFile = (file) => {
    return { file: file._id, enabled: true, metadata: { ...file } };
  };

  // Share
  const [showShare, setShowShare] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { copy } = useClipboardComponent();

  const { setShareLink, shareLink } = useShareLinkTool();

  const onShareTool = async () => {
    setLoading(true);
    setShareLink(null);
    toast.loading(
      'Crafting a link to pass the template along, with a sample of how it works in action.',
      {
        id: 'loading-share-tool',
      }
    );
    try {
      let newContent = promptValue;
      const dataTemplateVariables = watch();

      Object.entries(dataTemplateVariables).forEach(([key, value]) => {
        if (value !== '' && value)
          newContent = newContent?.replace('${' + key + '}', `"' ${value} '"`);
      });

      const sendData = {
        title: selectedTool.title,
        creator: store.profile._id,
        tool_id: selectedTool._id,
        prompt_id: selectedTool.prompts[0]._id,
        newContent,
        values: dataTemplateVariables,
      };

      // Generate new share tool
      const res = await store.api.post('/shareTools', sendData);
      const data = await res.data;
      // Copy to clipboard
      let url = await `${config.baseFrontUrl}/share/tool/${data.share_id}`;
      if (store.profile.nickname_fpr)
        url = `${url}?fpr=${store.profile.nickname_fpr}`;

      setShareLink(url);
      copy(url);

      toast.success(
        'All set! The template link has been saved to your clipboard for easy sharing.',
        {
          duration: 10000,
        }
      );
      setShowShare(true);
    } catch (error) {
      toast.error('Error sharing tool');
    } finally {
      toast.dismiss('loading-share-tool');
      setLoading(false);
    }
  };

  const checkExamplesInPrompt = () => {
    let hasSample = false;
    selectedPrompt?.prompts.forEach((prompt) => {
      if (prompt.example !== '') {
        hasSample = true;
      }
    });
    return hasSample;
  };

  const [runningSample, setRunningSample] = useState(false);
  const watchedValues = useWatch({ control: methods.control });

  const [runSampleVisible, setRunSampleVisible] = useState(
    checkExamplesInPrompt()
  );

  useEffect(() => {
    const hasValues = Object.values(watchedValues).some(
      (value) => value !== ''
    );
    const hasExamples = checkExamplesInPrompt();
    if (hasExamples) {
      setRunSampleVisible(!hasValues);
    }
  }, [watchedValues]);

  useEffect(() => {
    selectedPrompt?.prompts.forEach((prompt) => {
      setValue(prompt.attr, '');
    });
  }, [selectedPrompt]);

  useEffect(() => {
    const hasExamples = checkExamplesInPrompt();
    setRunSampleVisible(hasExamples);
  }, [selectedPrompt]);

  const onRunSample = async () => {
    //TODO para correr el ejemplo
    try {
      const modelIdsArray = selectedTool?.default_model?.id
        ? [selectedTool.default_model.id]
        : [];

      handleTemplateModel(modelIdsArray);

      selectedPrompt?.prompts.forEach((prompt) => {
        setValue(prompt.attr, prompt.example);
      });
      setRunningSample(true);
      setTimeout(() => {
        handleSendData(true);
      }, 1000);
      return 'Sample values have been set.';
    } catch (error) {
      return 'Error setting sample values.';
    }
  };

  const [sendDirect, setSendDirect] = useState(false);

  const onSendDirectly = async (data) => {
    const attachments = selectedPrompt?.prompts?.filter((i) =>
      ['image', 'attachment'].includes(i.type)
    );

    // Delete for data the keys that exist in the attachments
    attachments?.forEach((attachment) => {
      delete data[attachment.attr];
    });

    // conditions to pass the data
    const emptyFields = validateObjectHasEmptyFields(data);
    const emptyAttachments =
      attachments?.length > 0 &&
      Object.values(watch('files') ?? {}).length === 0;
    // handleSendData(true);
    if (emptyFields || emptyAttachments) {
      setSendDirect(true);
      setErrorModal(true);
      setErrorModalReason({
        emptyFields,
        emptyAttachments,
      });
    } else {
      handleSendData(true);
    }
  };

  const { theme } = useThemeStore();

  const veriftyEmoji = (tool) => {
    let iconTag = 'high-voltage';
    if (tool?.Icon) {
      if (tool.Icon !== ' ') {
        iconTag = tool.Icon;
      }
    }
    return <Emoji emoji={iconTag} />;
  };
  const [favTool, setFavTool] = useState(false);

  useEffect(() => {
    if (selectedTool) {
      setFavTool(selectedTool.favorite);
    }
  }, [selectedTool]);

  const onClickFav = async () => {
    const profile = JSON.parse(localStorage.getItem('profile'));

    if (!selectedTool || !profile) {
      toast.error('Invalid operation');
      return;
    }

    const isCurrentlyFavored = favTool;
    const endpoint = isCurrentlyFavored
      ? '/tool/remove-user-tool-fav'
      : '/tool/add-user-tool-fav';
    const method = isCurrentlyFavored ? 'put' : 'post';

    // Optimistically toggle the favorite for a smooth UX
    setFavTool(!isCurrentlyFavored);

    const postData = {
      userId: profile._id,
      toolId: selectedTool._id,
    };

    try {
      // const response = await store.api.post(endpoint, postData);
      const response =
        method === 'put'
          ? await store.api.put(endpoint, postData)
          : await store.api.post(endpoint, postData);
      // console.log(response);

      toast.success(
        `Template ${
          isCurrentlyFavored ? 'removed from' : 'added to'
        } your favorites`
      );
    } catch (error) {
      console.error('Failed to update favorites:', error);
      // Revert the toggle on error
      setFavTool(isCurrentlyFavored);
      toast.error('Failed to update favorites');
    }
  };

  //------------------ Go back to library ------------------
  const [goBackLibrary, setGoBackLibrary] = useState(false);
  const { setOpenPromptLibrary } = usePromptLibraryStore();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('from') === 'promptLibrary') {
      setGoBackLibrary(true);
    } else {
      setGoBackLibrary(false);
    }
  }, [location]);
  const handleGoBackLibrary = () => {
    setOpenPromptLibrary(true);
    onClose();
  };
  //-----------------------------------------------------

  return (
    <>
      <CommonModal
        open={openModal}
        handleClose={handleCloseClickOutside}
        maxWidth="sm"
      >
        <FormProvider {...methods}>
          <div className="font-figtree">
            <div className="flex gap-2 items-center min-w-[350px] md:min-w-[500px] justify-between">
              <div className="flex flex-col w-full">
                <Tooltip title={''}>
                  <div className="hover:bg-ghost-white absolute top-2 right-2 rounded-full">
                    <XMarkIcon
                      className="w-4 h-4 cursor-pointer text-cool-gray"
                      onClick={handleCloseClickOutside}
                    />
                  </div>
                </Tooltip>
                <div
                  className={cn(
                    'mb-2  gap-2 justify-between items-center w-full',
                    {
                      flex: !goBackLibrary,
                    }
                  )}
                >
                  {goBackLibrary && (
                    <Button
                      size="small"
                      onClick={handleGoBackLibrary}
                      startIcon={<ArrowLeftCircleIcon className="w-6 h-6" />}
                    >
                      Back to prompts
                    </Button>
                  )}
                  <p className="line-clamp-1 font-bold uppercase text-sm text-nue-blue">
                    Prompt Template
                  </p>
                </div>
                <div className="flex justify-center items-center space-x-1">
                  <div className="text-4xl">{veriftyEmoji(selectedTool)}</div>
                  <div className="uppercase text-raisin-black dark:text-crystal-bell text-lg font-bold w-full truncate text-pretty">
                    {selectedTool?.title}
                  </div>
                </div>
              </div>
              <div
                className="rounded-md border-raisin-black cursor-pointer"
                onClick={onClickFav}
              >
                <Tooltip
                  title={favTool ? 'Remove from favorites' : 'Add to favorites'}
                  arrow
                  placement="right-start"
                >
                  {favTool && <StarIcon className="w-5 h-5 text-yellow-400" />}
                  {/* <StarIcon className="w-8 h-8 text-graphite-100" /> */}
                  {!favTool && (
                    <StarOutlineIcon className="w-5 h-5 border-raisin-black text-raisin-black dark:text-crystal-bell" />
                  )}
                  {/* <Emoji emoji={'star'} className="w-8 h-8" /> */}
                </Tooltip>
              </div>
            </div>
            <div className="flex">
              {selectedTool?.multi_default_models.length > 0 ? (
                <div className="flex space-x-2 overflow-x-scroll">
                  {selectedTool?.multi_default_models.map((model, index) => (
                    <div
                      key={index}
                      className="mt-1 mb-4 bg-nue-blue text-white px-1 rounded"
                      style={{
                        display: 'inline-block',
                        width: 'fit-content',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <span className="text-xs font-semibold px-1 text-white">
                        {model.name}
                      </span>
                    </div>
                  ))}
                </div>
              ) : selectedTool?.default_model ? (
                <div>
                  {' '}
                  {selectedTool?.default_model &&
                    Object.keys(selectedTool?.default_model).length > 0 && (
                      <div
                        className="mt-1 mb-4  bg-nue-blue text-white px-1 rounded"
                        style={{
                          display: 'inline-block',
                          width: 'fit-content',
                        }}
                      >
                        <span className="text-xs font-semibold px-1 text-white">
                          {selectedTool?.default_model?.name}
                        </span>
                      </div>
                    )}
                </div>
              ) : (
                <div>{/* Add content here if needed */}</div>
              )}
            </div>

            <div className="mt-1 mb-4 text-sm italic text-cool-gray dark:text-quicksilver">
              {selectedTool?.desc}
            </div>
            <div className={cn('grid gap-1', `grid-cols-${tabs.length ?? 1}`)}>
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  icon={tab.icon}
                  text={tab.text}
                  value={tab.value}
                  currentValue={currentTab}
                  onClick={handleChangeTab}
                />
              ))}
            </div>
            <hr className="mt-4 bg-platinum" />
            <div className="mt-4 max-h-[40vh] overflow-y-auto duration-500 ease-in-out">
              {TABS.find((tab) => tab.value === currentTab)?.render()}
            </div>
            <hr className="mt-4 bg-platinum" />
            <div className="flex justify-between gap-2 mt-4">
              <LoadingButton
                sx={{
                  textTransform: 'capitalize',
                  borderColor: theme === 'light' ? '#5256A6' : '#7379FF',
                }}
                variant="outlined"
                color="inherit"
                startIcon={
                  <LinkIcon className="w-4 h-4 text-raisin-black dark:text-crystal-bell" />
                }
                loading={loading}
                onClick={onShareTool}
              >
                <span className="font-semibold text-raisin-black dark:text-crystal-bell">
                  Create public URL
                </span>
              </LoadingButton>
              {runSampleVisible && (
                <LoadingButton
                  sx={{
                    textTransform: 'capitalize',
                    borderColor: theme === 'light' ? '#5256A6' : '#7379FF',
                  }}
                  variant="outlined"
                  color="inherit"
                  startIcon={
                    <BoltIcon className="w-4 h-4 text-raisin-black dark:text-crystal-bell" />
                  }
                  onClick={handleSubmit(onRunSample)}
                >
                  <span className="font-semibold text-raisin-black dark:text-crystal-bell">
                    Run sample
                  </span>
                </LoadingButton>
              )}
              {!runSampleVisible && !selectedTool?.useTool && (
                <LoadingButton
                  sx={{
                    textTransform: 'capitalize',
                    borderColor: theme === 'light' ? '#5256A6' : '#7379FF',
                  }}
                  variant="outlined"
                  color="inherit"
                  startIcon={
                    <PaperAirplaneIcon className="w-4 h-4 text-raisin-black dark:text-crystal-bell" />
                  }
                  onClick={handleSubmit(onSendDirectly)}
                >
                  <span className="font-semibold text-raisin-black dark:text-crystal-bell">
                    Run
                  </span>
                </LoadingButton>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit(handleInsert)}
                startIcon={<ArrowDownTrayIcon className="w-4 h-4" />}
              >
                <span className="font-bold">Insert</span>
              </Button>
            </div>
            {showShare && (
              <div>
                {shareLink && (
                  <div className="mb-2 mt-4">
                    <ShareInput shareLink={shareLink} />
                  </div>
                )}
                <p className="pt-2 text-raisin-black dark:text-crystal-bell">
                  🚀 Boost sign-ups and earn with every share! Join{' '}
                  <strong>Straico's affiliate program</strong> and convert your
                  shares into a revenue stream. 💰{' '}
                  <a
                    className="text-violet-blue cursor-pointer"
                    href="https://straico.com/affiliate/"
                    target="__blank"
                  >
                    Start here!
                  </a>
                </p>
              </div>
            )}
          </div>
        </FormProvider>
      </CommonModal>
      <ErrorModal handleConfirm={handleSendData} sendDirect={sendDirect} />
      <ModalConfirmClose
        open={confirmCloseModal}
        onCancel={handleCancelConfirmClose}
        onClose={handleConfirmClose}
      />
    </>
  );
};

export default inject('store')(observer(TemplateModal));
