import Anthropic from './anthropic';
import Cohere from './cohere';
import Deepseek from './deepseek';
import Goliath from './goliath';
import Google from './google';
import Gryphe from './gryphe';
import Meta from './meta';
import Mistral from './mistral';
import Nous from './nous';
import OpenAI from './openAI';
import Perplexity from './perplexity';
import Qwen from './qwen';
import Reflection from './reflection';

const modelIconsPalette = {
  Anthropic: {
    100: (props) => <Anthropic {...props} fillColor="#CC9B7A" />,
    200: (props) => <Anthropic {...props} fillColor="#A67858" />,
    300: (props) => <Anthropic {...props} fillColor="#815639" />,
    400: (props) => <Anthropic {...props} fillColor="#5D371B" />,
    500: (props) => <Anthropic {...props} fillColor="#3C1A00" />,
  },
  Cohere: {
    100: (props) => <Cohere {...props} fillColor="#98BBAD" />,
    200: (props) => <Cohere {...props} fillColor="#789B8D" />,
    300: (props) => <Cohere {...props} fillColor="#58796C" />,
    400: (props) => <Cohere {...props} fillColor="#39594D" />,
    500: (props) => <Cohere {...props} fillColor="#103025" />,
  },
  Deepseek: {
    100: (props) => <Deepseek {...props} fillColor="#9D85FF" />,
    200: (props) => <Deepseek {...props} fillColor="#7969FF" />,
    300: (props) => <Deepseek {...props} fillColor="#514DFE" />,
    400: (props) => <Deepseek {...props} fillColor="#2E2BE9" />,
    500: (props) => <Deepseek {...props} fillColor="#00009A" />,
  },
  Goliath: {
    100: (props) => <Goliath {...props} fillColor="#75AA74" />,
    200: (props) => <Goliath {...props} fillColor="#548854" />,
    300: (props) => <Goliath {...props} fillColor="#336736" />,
    400: (props) => <Goliath {...props} fillColor="#124719" />,
    500: (props) => <Goliath {...props} fillColor="#002A00" />,
  },
  Google: {
    100: (props) => <Google {...props} fillColor="#4286F5" />,
    200: (props) => <Google {...props} fillColor="#006AD4" />,
    300: (props) => <Google {...props} fillColor="#004FB4" />,
    400: (props) => <Google {...props} fillColor="#003695" />,
    500: (props) => <Google {...props} fillColor="#002077" />,
  },
  Gryphe: {
    100: (props) => <Gryphe {...props} fillColor="#7479AA" />,
    200: (props) => <Gryphe {...props} fillColor="#595F8E" />,
    300: (props) => <Gryphe {...props} fillColor="#3F4773" />,
    400: (props) => <Gryphe {...props} fillColor="#262F5A" />,
    500: (props) => <Gryphe {...props} fillColor="#0A1A41" />,
  },
  Meta: {
    100: (props) => <Meta {...props} fillColor="#578DFF" />,
    200: (props) => <Meta {...props} fillColor="#2F7EF4" />,
    300: (props) => <Meta {...props} fillColor="#0968E0" />,
    400: (props) => <Meta {...props} fillColor="#004CBE" />,
    500: (props) => <Meta {...props} fillColor="#1831B5" />,
  },
  Mistral: {
    100: (props) => <Mistral {...props} fillColor="#E78D66" />,
    200: (props) => <Mistral {...props} fillColor="#BF6A45" />,
    300: (props) => <Mistral {...props} fillColor="#974926" />,
    400: (props) => <Mistral {...props} fillColor="#712807" />,
    500: (props) => <Mistral {...props} fillColor="#4D0500" />,
  },
  Nous: {
    100: (props) => <Nous {...props} fillColor="#27A3EA" />,
    200: (props) => <Nous {...props} fillColor="#0D88CF" />,
    300: (props) => <Nous {...props} fillColor="#0071A9" />,
    400: (props) => <Nous {...props} fillColor="#005EB2" />,
    500: (props) => <Nous {...props} fillColor="#003E99" />,
  },
  OpenAI: {
    100: (props) => <OpenAI {...props} fillColor="#74AA9C" />,
    200: (props) => <OpenAI {...props} fillColor="#52877A" />,
    300: (props) => <OpenAI {...props} fillColor="#31665A" />,
    400: (props) => <OpenAI {...props} fillColor="#0E463B" />,
    500: (props) => <OpenAI {...props} fillColor="#00291F" />,
  },
  Perplexity: {
    100: (props) => <Perplexity {...props} fillColor="#63A6B2" />,
    200: (props) => <Perplexity {...props} fillColor="#428792" />,
    300: (props) => <Perplexity {...props} fillColor="#1F6974" />,
    400: (props) => <Perplexity {...props} fillColor="#003944" />,
    500: (props) => <Perplexity {...props} fillColor="#001E27" />,
  },
  Qwen: {
    100: (props) => <Qwen {...props} fillColor="#AA7481" />,
    200: (props) => <Qwen {...props} fillColor="#8C5966" />,
    300: (props) => <Qwen {...props} fillColor="#703F4C" />,
    400: (props) => <Qwen {...props} fillColor="#542633" />,
    500: (props) => <Qwen {...props} fillColor="#390D1C" />,
  },
  Reflection: {
    100: (props) => <Reflection {...props} fillColor="#A47EC2" />,
    200: (props) => <Reflection {...props} fillColor="#8560A2" />,
    300: (props) => <Reflection {...props} fillColor="#664483" />,
    400: (props) => <Reflection {...props} fillColor="#492965" />,
    500: (props) => <Reflection {...props} fillColor="#2C0F49" />,
  },
};

const convert = (palette) => {
  const newObject = {};

  Object.keys(palette).forEach((key) => {
    const value = palette[key];

    if (typeof value === 'object') {
      Object.keys(value).forEach((variantKey) => {
        const newKey = `${key}-${variantKey}`;
        newObject[newKey] = value[variantKey];
      });

      if (value[100]) {
        newObject[key] = value[100];
      }
    }
  });

  return newObject;
};

export const icons = convert(modelIconsPalette);
